import { Alert, AlertTitle } from '@material-ui/lab'
import { Button, Link } from '@material-ui/core'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
import Router, { useRouter } from 'next/router'

import { AppProps } from 'next/app'
import { Auth0Provider } from '@auth0/auth0-react'
import CssBaseline from '@material-ui/core/CssBaseline'
import React from 'react'

import { ReactQueryDevtools } from 'react-query-devtools'
import RouterLink from 'next/link'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../utils/theme'
import { useAuth0 } from '@auth0/auth0-react'

const queryCache = new QueryCache()

const onRedirectCallback = (appState) => {
  // Router.replace(appState?.returnTo || '/')
}

const LogoutButton = () => {
  const { logout } = useAuth0()
  return (
    <Button onClick={() => logout({ returnTo: window?.location?.origin || '/' })}>
      Logg inn med en annen bruker
    </Button>
  )
}

const ErrorMessage = ({
  error,
  error_description,
}: {
  error: string | string[]
  error_description: string | string[]
}) => {
  return (
    <>
      <Alert severity="error">
        <AlertTitle>{error}</AlertTitle>
        {error_description}
      </Alert>
      <RouterLink href="/">
        <Link>Prøv igjen</Link>
      </RouterLink>
      <LogoutButton></LogoutButton>
    </>
  )
}

// https://github.com/vercel/next.js/issues/8592
//@ts-ignore
function App({ Component, pageProps, err }: AppProps) {
  const [authError, setAuthError] = React.useState(null)
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])
  const router = useRouter()
  React.useEffect(() => {
    // Check router
    console.log('Router in effect', router, router.asPath, router.query)
    const { error, error_description } = router.query
    if (error || router.asPath.includes('error')) {
      setAuthError({ error, error_description })
    }
  }, [router])

  console.log('Router', router, router.asPath, router.query)

  if (authError) {
    return (
      <Auth0Provider
        domain={process.env.AUTH0_DOMAIN}
        clientId={process.env.AUTH0_CLIENT_ID}
        //        audience={process.env.AUTH0_AUDIENCE}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        redirectUri={typeof window !== 'undefined' && window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <ReactQueryCacheProvider queryCache={queryCache}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ErrorMessage
              error={authError.error}
              error_description={authError.error_description}
            ></ErrorMessage>
          </ThemeProvider>
        </ReactQueryCacheProvider>
      </Auth0Provider>
    )
  }
  // return <div>Not error</div>
  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN}
      clientId={process.env.AUTH0_CLIENT_ID}
      //      audience={process.env.AUTH0_AUDIENCE}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      redirectUri={typeof window !== 'undefined' && window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <ReactQueryCacheProvider queryCache={queryCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Component {...pageProps} err={err} />
        </ThemeProvider>
      </ReactQueryCacheProvider>
    </Auth0Provider>
  )
}

// export async function getServerSideProps(context) {
//   return {
//     props: {}, // will be passed to the page component as props
//   }
// }

export default App

import { createTheme } from '@material-ui/core/styles'

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: ['graphik-regular', '-apple-system', 'BlinkMacSystemFont', 'Inter', 'sans-serif'].join(','),
    // h5: {
    //     fontSize: 16,
    // }
    h6: {
      fontFamily: ['graphik-semibold', '-apple-system', 'BlinkMacSystemFont', 'Inter', 'sans-serif'].join(','),
    }
  },
  spacing: 8,
  overrides: {
    MuiButton: {
     
      contained: {
        fontFamily: ['graphik-semibold', '-apple-system', 'BlinkMacSystemFont', 'Inter', 'sans-serif'].join(','),
        background: '#61FFA0',
        // color: '#fff'
        border: '3px solid #000',
        borderRadius: 0,
        boxShadow: 'none'

      },
      containedSecondary: {
        // color: '#fff'

      }


    }
  },
  palette: {
    primary: {
      main: '#292e33',
    },
    secondary: {
      main: '#61FFA0',
    },
    
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
    grey: {
      50: '#fafafa',
      100: '#F7F7F7',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    info: {
      main: '#2196f3',
    },
    success: {
      main: '#4caf50',
    },
    warning: {
      main: '#ff9800',
    },
    error: {
      main: '#f44336',
    },
    background: {
      default: '#eff3f5',
      paper: '#fff',
    },
  },
})

export default theme
